<template>
  <div>
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 员工资料
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="24">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
          class="formBox"
        >
          <el-form-item label="状态:">
            <el-radio-group v-model="detail.status">
              <el-radio :label="1">激活</el-radio>
              <el-radio :label="2">禁用</el-radio>
              <el-radio :label="3">离职</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="detail.status == 2" label="禁用原因:">
            <el-input v-model="detail.reason" class="w300" maxlength="150" show-word-limit />
          </el-form-item>
          <el-form-item label="所在门店:" prop="shopId">
            <el-button v-if="!detail.shopName" type="primary" @click="addShop" size="mini">选择门店</el-button>
            <el-button v-if="detail.shopName" type="primary" @click="addShop" size="mini" plain>
              {{detail.shopName}}
              <i class="el-icon-sort ml20" />
            </el-button>
          </el-form-item>
          <el-form-item label="员工姓名:" prop="name">
            <el-input v-model="detail.name" class="w300" maxlength="150" show-word-limit />
          </el-form-item>
          <el-form-item label="性别:">
            <el-radio-group v-model="detail.sex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="员工电话:" prop="phone">
            <el-input v-model="detail.phone" class="w300" placeholder="请填写员工电话" />
          </el-form-item>
          <el-form-item label="是否参与预约:" prop="isReservation">
            <el-radio-group v-model="detail.isReservation">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="员工年龄:" prop="age">
            <el-input v-model="detail.age" class="w300" placeholder="请填写员工年龄" />
          </el-form-item>

          <el-form-item label="员工等级:" prop="gradeId">
            <el-select
              v-model="detail.gradeId"
              class="w300"
              clearable
              placeholder="请选择等级"
              @change="changeGrade"
            >
              <el-option
                v-for="item in options.gradeId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="职位:" prop="stationId">
            <el-select
              v-model="detail.stationId"
              class="w300"
              clearable
              placeholder="请选择职位"
              @change="changeDtation"
            >
              <el-option
                v-for="item in options.stationId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="岗位:" prop="positionId">
            <el-select
              v-model="detail.positionId"
              class="w300"
              clearable
              placeholder="请选择岗位"
              @change="changePosition"
            >
              <el-option
                v-for="item in options.positionId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="员工角色:" prop="groupId">
            <el-select
              v-model="detail.groupId"
              class="w300"
              clearable
              placeholder="请选择角色"
              @focus="focusRole"
            >
              <el-option
                v-for="item in options.groupId"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="员工生日:" prop="birthday">
            <el-date-picker
              v-model="detail.birthday"
              type="date"
              class="w300"
              value-format="yyyy-MM-dd"
              placeholder="请选择员工生日"
            />
          </el-form-item>
          <el-form-item label="入职时间:" prop="entryAt">
            <el-date-picker
              v-model="detail.entryAt"
              type="date"
              class="w300"
              value-format="yyyy-MM-dd"
              placeholder="请选择入职时间"
            />
          </el-form-item>
          <el-form-item label="通过试用期:">
            <el-radio-group v-model="detail.isProbation">
              <el-radio :label="2">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否客户转移:" v-if="transferVisible">
            <el-radio-group v-model="detail.isTransfer">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="detail.status == 3" label="离职时间:" prop="leaveAt">
            <el-date-picker
              v-model="detail.leaveAt"
              type="date"
              class="w300"
              value-format="yyyy-MM-dd"
              placeholder="请选择离职时间"
            />
          </el-form-item>
          <el-form-item v-if="id">
            <el-button v-if="!amendPas" type="primary" size="mini" @click="amendPassword">修改密码</el-button>
            <el-button v-else type="primary" size="mini" @click="amendPassword">暂不修改密码</el-button>
          </el-form-item>
          <el-form-item v-if="amendPas || !id" label="登录密码:" prop="password">
            <el-input v-model="detail.password" class="w300" show-password placeholder="请填写新登录密码" />
          </el-form-item>
          <div class="btnBox">
            <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">提 交</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- 门店弹框 -->
    <el-dialog title="选择门店" :visible.sync="dialogVisible" width="80%">
      <el-row class="bgW">
        <el-col :span="22">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
            <el-form-item label="门店名称">
              <el-input
                v-model="search.shopName"
                maxlength="30"
                class="w150"
                placeholder="请输入门店名称"
                @keyup.enter.native="getList('search')"
              />
            </el-form-item>
            <el-form-item label="门店金蝶编码">
              <el-input
                v-model="search.keycode"
                maxlength="30"
                class="w150"
                placeholder="请输入门店金蝶编码"
                @keyup.enter.native="getList('search')"
              />
            </el-form-item>
            <el-form-item label="归属市场">
              <el-select
                v-model="search.marketId"
                clearable
                class="w150"
                placeholder="请选择统归属市场"
                @change="getList('search')"
              >
                <el-option
                  v-for="item in options.marketId"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="大区归属">
              <el-select
                v-model="search.regionId"
                clearable
                class="w150"
                placeholder="请选择统大区归属"
                @change="getList('search')"
              >
                <el-option
                  v-for="item in options.regionId"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="mini"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="门店名称" prop="shopName" />
            <ElTableColumn label="门店电话" prop="shopMobile" />
            <ElTableColumn label="店长" prop="shopOwner" />
            <ElTableColumn label="店长电话" prop="shopPhone" />
            <ElTableColumn label="归属市场" prop="marketName" />
            <ElTableColumn label="大区归属" prop="regionName" />
            <ElTableColumn label="销售金额(单位分)" prop="saleAmt" />
            <ElTableColumn label="状态" width="80">
              <template slot-scope="{ row }">
                <div v-if="row.status ==1">运营中</div>
                <div v-if="row.status ==2">临时关闭</div>
                <div v-if="row.status ==3">永久关闭</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="客户" prop="customerNum" />
            <ElTableColumn label="操作" width="100" fixed="right">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="hint(row,'此员工添加到该门店')">确 定</el-button>
              </template>
            </ElTableColumn>
          </el-table>

          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { ssettingList } from "@/api/client";
import { roleList } from "@/api/permission";
import {
  createStaff,
  readAdminPositionList,
  readAdminStationList,
  staffDetail,
  updateStaff
} from "@/api/staff";
import {
  getShopList,
  readAdminMarketList,
  readAdminRegionList
} from "@/api/shop";

class Detail {
  status = 1;
  reason = "";
  shopId = "";
  shopName = "";
  marketName = "";
  name = "";
  phone = "";
  gradeId = "";
  gradeName = "";
  age = "";
  sex = "男";
  stationId = "";
  stationName = "";
  positionId = "";
  positionName = "";
  groupId = "";
  birthday = "";
  entryAt = "";
  isProbation = 2;
  probation = "";
  leaveAt = "";
  password = "";
  isTransfer = 0;
  isReservation = 1;
}
class Rules {
  isReservation = [{ required: true, message: "请确定员工是否参与预约", trigger: "blur" }];
  shopId = [{ required: true, message: "请选择所在门店", trigger: "change" }];
  name = [{ required: true, message: "请填写员工姓名", trigger: "blur" }];
  phone = [
    { required: true, message: "请填写员工电话", trigger: "blur" },
    {
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      message: "手机号格式不对",
      trigger: "change"
    }
  ];

  age = [{ required: true, message: "请填写员工年龄", trigger: "blur" }];
  gradeId = [{ required: true, message: "请选择员工等级", trigger: "change" }];
  stationId = [
    { required: true, message: "请选择员工职位", trigger: "change" }
  ];
  positionId = [
    { required: true, message: "请选择员工岗位", trigger: "change" }
  ];
  groupId = [{ required: true, message: "请选择员工角色", trigger: "change" }];

  birthday = [{ required: true, message: "请选择员工生日", trigger: "change" }];
  entryAt = [{ required: true, message: "请选择入职时间", trigger: "change" }];
  leaveAt = [{ required: true, message: "请选择离职时间", trigger: "change" }];
  password = [{ required: true, message: "请填写登录密码", trigger: "blur" }];
}
class Options {
  constructor() {
    this.stationId = [];
    this.positionId = [];
    this.gradeId = [];
    this.marketId = [];
    this.groupId = [];
    this.regionId = [{ id: 0, name: "全部" }];
  }
}
class Search {
  shopName = ""; // 门店名称
  keycode = ""; // 门店金蝶编码
  marketId = ""; // 归属市场
  regionId = ""; // 归属大区
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "AddStaff",
  components: {
    pagination
  },

  data() {
    return {
      page: new Page(), // 分页
      detail: new Detail(),
      rules: new Rules(),
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      list: [], // 门店展示
      active: 0,
      dialogVisible: false,
      amendPas: false, //修改按钮
      id: "",
      transferVisible:false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getOptions();
  },
  methods: {
    // 获取下拉框资源
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 10) {
            that.options.gradeId.push(item);
          }
        });
      });
      readAdminPositionList().then(res => {
        this.options.stationId = res.list;
      });

      readAdminStationList().then(res => {
        this.options.positionId = res.list;
      });
      readAdminMarketList().then(res => {
        this.options.marketId = res.list;
      });
      readAdminRegionList({ page: 1, limit: 10000 }).then(res => {
        this.options.regionId = res.list;
      });
      if (this.$route.query.id) {
        this.getDetail();
      }
    },
    // 获取订单详情
    getDetail() {
      staffDetail({ id: this.$route.query.id }).then(res => {
        this.detail = res;
        console.log(this.detail);
        if (this.detail.shopId) {
          this.getRole(this.detail.shopId);
        }
      });
    },
    // 获取角色之前
    focusRole() {
      // console.log(4135)
      if (!this.detail.shopId) {
        this.$message({
          type: "warning",
          message: "请先选择门店"
        });
      }
    },
    // 获取角色
    getRole(shopId) {
      roleList({ shopId: shopId }).then(res => {
        this.options.groupId = res;
      });
    },
    // 点击添加门店
    addShop() {
      this.getList();
      this.dialogVisible = true;
    },
    // 获取门店列表
    getList(option) {
      if (option == "search") this.page = new Page();
      getShopList({ ...this.search, ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },

    // 改变等级
    changeGrade(e) {
      let that = this;
      that.options.gradeId.forEach(function(val) {
        if (e == val.id) {
          that.detail.gradeName = val.name;
        }
      });
    },

    // 改变职位
    changeDtation(e) {
      let that = this;
      that.options.stationId.forEach(function(val) {
        if (e == val.id) {
          that.detail.stationName = val.name;
        }
      });
    },
    // 改变岗位
    changePosition(e) {
      let that = this;
      that.options.positionId.forEach(function(val) {
        if (e == val.id) {
          that.detail.positionName = val.name;
        }
      });
    },
    // 修改登录密码
    amendPassword() {
      this.amendPas = !this.amendPas;
    },
    tapEnsure(row) {
      console.log(row.shopId);
      console.log(this.detail.shopId);
      if(this.detail.shopId == row.shopId)
      {
        this.transferVisible = false;
      }else{
        this.transferVisible = true;
      }
      this.detail.shopId = row.shopId;
      this.detail.shopName = row.shopName;
      this.detail.marketName = row.marketName;
      this.dialogVisible = false;
      this.getRole(this.detail.shopId);
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("add", "添加此员工");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "add") {
            this.confirm();
          } else {
            this.tapEnsure(type);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    confirm() {
      if (this.$route.query.id) {
        updateStaff({ ...this.detail, id: this.$route.query.id }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./staffList");
        });
      } else {
        createStaff({ ...this.detail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./staffList");
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;

  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    padding: 40px 0 0 260px;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .btnBox {
      width: 100%;
      text-align: center;
    }
    .mainBtn {
      margin: 30px 16% 0 0;
      padding: 8px 40px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
    .formBox {
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        width: 44%;
      }
    }
  }
}
</style>
